<template>
  <div v-if="page_loading">
      <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />
    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title  style="height: 60px;">
        <h4>{{use_language.report}}</h4> <v-icon>mdi-chevron-right</v-icon><a @click="backpage()"><span class="font-weight-regular body-1">{{use_language.sales}}</span></a><v-icon>mdi-chevron-right</v-icon><span class="font-weight-regular body-1">{{use_language.total_sales_all}}</span>
      </v-card-title>
    </v-card>
    <v-card class="v-card-bottom-30">
      <v-card-text>
        <v-row>
          <v-col class="pb-0" cols="12" md="2" lg ="2" xl="1"><b> {{use_language.start_}} :</b></v-col>
          <v-col class="pb-0" cols="12" md="8" lg= "6" xl="6">
            <v-menu
                ref="search_menu_date_from"
                v-model="search_menu_date_from"
                :close-on-content-click="false"
                :return-value.sync="search_date_from"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      :value="computed_search_date_from"
                      @change="value => computed_search_date_from = value"
                      autocomplete="off"
                      outlined
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      dense
                      hide-details
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="search_date_from" @input="search_menu_date_from = false, $refs.search_menu_date_from.save(search_date_from)" no-title :allowed-dates="allowedDates" class="my-0">
                </v-date-picker>
              </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0" cols="12" md="2" lg ="2" xl="1"> <b>{{use_language.to_}} : </b> </v-col>
          <v-col class="pb-0" cols="12" md="8" lg= "6" xl="6">
            <v-menu
                ref="search_menu_date_to"
                v-model="search_menu_date_to"
                :close-on-content-click="false"
                :return-value.sync="search_date_to"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      :value="computed_search_date_to"
                      @change="value => computed_search_date_to = value"
                      autocomplete="off"
                      outlined
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      dense
                      hide-details
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="search_date_to" @input="search_menu_date_to = false, $refs.search_menu_date_to.save(search_date_to)" no-title :allowed-dates="allowedDates" class="my-0">
                </v-date-picker>
              </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0" cols="12" md="2" lg ="2" xl="1"> <b>{{use_language.admin}} :</b> </v-col>
          <v-col class="pb-0" cols="12" md="8" lg="6" xl="6">
            <v-autocomplete
              v-model="select_admin"
              :items="items_admin"
                hide-details
                outlined
                dense
                chips
                item-text="name"
                item-value="id"
                small-chips
                label="ทั้งหมด"
                multiple
              >
              <template v-slot:label> <span>{{use_language.all_}}</span> </template> 
              </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0" cols="12" md="2" lg ="2" xl="1"> <b>{{use_language.sales_channel}} : </b> </v-col>
          <v-col class="pb-0" cols="12" md="8" lg="6" xl="6">
            <v-autocomplete
                v-model="select_chanal"
                :items="items_chanal"
                hide-details
                outlined
                dense
                chips
                item-text="channelName"
                item-value="id"
                small-chips
                label="ทั้งหมด"
                multiple
              >
              <template v-slot:label> <span>{{use_language.all_}}</span> </template> 
              </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0" cols="12" md="2" lg ="2" xl="1"> <b>{{use_language.treasury}} : </b> </v-col>
          <v-col class="pb-0" cols="12" md="8" lg="6" xl="6">
            <v-autocomplete
                v-model="select_warehouse"
                :items="items_warehouse"
                hide-details
                outlined
                dense
                chips
                item-text="name"
                item-value="id"
                small-chips
                label="ทั้งหมด"
                multiple
              >
               <template v-slot:label> <span>{{use_language.all_}}</span> </template> 
              </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0" cols="12" md="2" lg ="2" xl="1"> <b>{{use_language.province}} :</b> </v-col>
          <v-col class="pb-0" cols="12" md="8" lg="6" xl="6">
            <v-autocomplete
                v-model="select_provice"
                :items="items_provice"
                hide-details
                outlined
                dense
                chips
                item-text="name"
                item-value="id"
                small-chips
                label="ทั้งหมด"
                multiple
              >
               <template v-slot:label> <span>{{use_language.all_}}</span> </template> 
              </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="2" lg ="2" xl="1"></v-col>
          <v-col cols="12" md="8" lg="6" xl="6">
            <v-btn color="primary" @click="searching() ,IsToday = '2'">{{use_language.search}}</v-btn>
            <v-btn @click="SearchingClearData()" outlined color="primary" class="mx-1">{{use_language.reset}}</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>


    <v-row justify="start" align="center" class="my-7 margin-card-status">
      <v-col cols="12" sm="12" md="12" lg="6" align="left">
        <v-card  dark color="teal" style="height: 175px;">
          <v-card-title>
           {{use_language.number_of_orders}}
          </v-card-title>
          <v-divider/>
          <v-card-title style="height : 135px;">
            <v-row align="center">
              <v-col cols="1" xl="1" align="left" style="height : 110px;" class="pa-0">
                <img src="@/assets/icon_jpg/i13.jpg"  style="width: 40px" />
              </v-col>
              <v-col cols="11" xl="11" align="center">
                <h2> <span>{{format_number(sum_order_total)}}</span> {{use_language.order}}</h2><br>
                <span>{{use_language.average}}</span> <span>{{format_price(averageOrderPerDay)}}</span> <span>{{use_language.order_day}}</span>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="6" align="left">
        <v-card dark color="primary" style="height: 175px;" >
          <v-card-title>
            {{use_language.total_sales}}
          </v-card-title>
          <v-divider/>
          <v-card-title style="height : 135px;">
            <v-row align="center">
              <v-col cols="1" xl="1" align="left" style="height : 110px;" class="pa-0">
                <img src="@/assets/icon_jpg/i14.jpg"  style="width: 40px" />
              </v-col>
              <v-col cols="11" xl="11" align="center">
                <h2> ฿ <span>{{format_price(sum_price_total)}}</span> {{use_language.bath}}</h2><br>
                <span>{{use_language.average_sales}}</span> <span>{{format_price(averageSalePerDay)}}</span> <span>{{use_language.baht_order}}</span>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>



    <v-card v-if="chartData_admin.length > 0" elevation="1" class="v-card-bottom-30">
      <v-card-title class="py-4">
        <v-icon size="20" color="success">fa-chart-area</v-icon> <span class="ml-3">{{use_language.total_sales}}</span>
        <v-spacer/>
      </v-card-title>
      <v-divider/>
      <v-card-text style="min-height: 350px;">
        <GChart
          type="LineChart"
          :data="chartData_admin"
          :options="chartOptions_admin.chart"
          max-width="100%"
        />
      </v-card-text>
    </v-card>


    <v-card v-if="chartData_warehouse.length > 0" elevation="1" class="v-card-bottom-30">
      <v-card-title class="py-4">
        <v-icon size="20" color="success">fa-chart-area</v-icon> <span class="ml-3">{{use_language.compare_sales_by_inventory}}</span>
        <v-spacer/>
      </v-card-title>
      <v-divider/>
      <v-card-text style="min-height: 350px;">
        <GChart
          type="LineChart"
          :data="chartData_warehouse"
          :options="chartOptions_warehouse.chart"
          max-width="100%"
        />
      </v-card-text>
    </v-card>


    <v-card v-if="chartData_chanal.length" elevation="1" class="v-card-bottom-30">
      <v-card-title class="py-4">
        <v-icon size="20" color="success">fa-chart-area</v-icon> <span class="ml-3">{{use_language.sales_by_sales_channel}}</span>
        <v-spacer/>
      </v-card-title>
      <v-divider/>
      <v-card-text style="min-height: 350px;">
        <GChart
          type="LineChart"
          :data="chartData_chanal"
          :options="chartOptions_chanal.chart"
          max-width="100%"
        />
      </v-card-text>
    </v-card>


    <v-row justify="start" align="center" class="my-7 margin-card-status">
      <v-col cols="12" sm="12" md="12" lg="6" align="left" v-if="chartPieData_chanal_price.length > 0">
        <v-card  elevation="1" class="v-card-bottom-30">
          <v-card-title class="py-4">
            <v-icon size="20" color="success">fa-chart-area</v-icon> <span class="ml-3">{{use_language.proportion_of_sales_by_sales_channel}}</span>
            <v-spacer/>
          </v-card-title>
          <v-divider/>
          <v-card-text style="min-height: 350px;">
            <GChart
              type="PieChart"
              :settings="{ packages: ['corechart'] }"
              :data="chartPieData_chanal_price"
              :options="chartPieOptions_chanal_price.chart"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="6" align="left" v-if="chartPieData_admin_price.length > 0">
        <v-card  elevation="1" class="v-card-bottom-30">
          <v-card-title class="py-4">
            <v-icon size="20" color="success">fa-chart-area</v-icon> <span class="ml-3">{{use_language.proportion_of_sales_by_salesperson}}</span>
            <v-spacer/>
          </v-card-title>
          <v-divider/>
          <v-card-text style="min-height: 350px;">
            <GChart
              type="PieChart"
              :settings="{ packages: ['corechart'] }"
              :data="chartPieData_admin_price"
              :options="chartPieOptions_admin_price.chart"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-card v-if="chartData_province.length > 0" elevation="1" class="v-card-bottom-30">
      <v-card-title class="py-4">
        <v-icon size="20" color="success">fa-chart-area</v-icon> <span class="ml-3">{{use_language.compare_sales_by_province}}</span>
        <v-spacer/>
      </v-card-title>
      <v-divider/>
      <v-card-text style="min-height: 350px;">
        <GChart
          type="ColumnChart"
          :data="chartData_province"
          :options="chartOptions_province.chart"

          ref="gChart"
        />
      </v-card-text>
    </v-card>


    <!-- ตาราง1 -->
    <v-card v-if="totalReportTable_list.length > 0" elevation="1" class="v-card-bottom-30">
      <v-card-title class="py-4">
        <v-icon size="20" color="success">fa-table</v-icon> <span class="ml-3">{{use_language.sales_summary_table}}</span>
        <v-spacer/>
        <v-btn size="1" color="success" dark class="mr-1"><v-icon >mdi-file-excel</v-icon>
          <vue-excel-xlsx
              :data="totalReportTable_list"
              :columns="excel_totalReportTable_header"
              :file-name='sales_summary_table_report'
              :shee-tname='sales_summary_table_report'
              >
              {{use_language.export_excel}}
          </vue-excel-xlsx>
        </v-btn>

      </v-card-title>
      <v-divider/>
      <v-card-text>
          <div style="min-height: 350px; max-height: 650px; overflow-y: auto;">
            <v-data-table
              :headers="totalReportTable_header"
              :items="totalReportTable_list"
              :items-per-page="5"
              item-key="id"
              class="elevation-0 packhai-border-table"
              style="min-height: 300px"
              mobile-breakpoint="0"
            >
            <template v-slot:item.no="{ item }">
              {{ totalReportTable_list.indexOf(item) + 1 }}
            </template>
            <template v-slot:item.orderCount="{ item }">
              {{  item.orderCount  }}
            </template>
            </v-data-table>
          </div>
      </v-card-text>
    </v-card>

    <!-- ตาราง3 -->
    <v-card v-if="provinceTable_list.length > 0" elevation="1" class="v-card-bottom-30">
      <v-card-title class="py-4">
        <v-icon size="20" color="success">fa-table</v-icon> <span class="ml-3">{{use_language.sales_by_province}}</span>
        <v-spacer/>
        <v-btn size="1" color="success" dark class="mr-1"><v-icon >mdi-file-excel</v-icon>
          <vue-excel-xlsx
              :data="provinceTable_list"
              :columns="excel_provinceTable_header"
         :file-name='sales_report_by_province'
        :sheet-name='sales_report_by_province'
              >
              {{use_language.export_excel}}
          </vue-excel-xlsx>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text >
        <div style="min-height: 350px; max-height: 650px; overflow-y: auto;">
          <v-data-table
            :headers="provinceTable_header"
            :items="provinceTable_list"
            :items-per-page="5"
            item-key="id"
            class="elevation-0 packhai-border-table"
            style="min-height: 300px"
            mobile-breakpoint="0"
          >
          <template v-slot:item.no="{ item }">
            {{ provinceTable_list.indexOf(item) + 1 }}
          </template>
          <template v-slot:item.orderCount="{ item }">
            {{  item.orderCount  }}
          </template>
          </v-data-table>
        </div>
      </v-card-text>
    </v-card>


    <!-- ตาราง 4 -->
    <v-card v-if="skuTable_list.length > 0" elevation="1" class="v-card-bottom-30">
      <v-card-title class="py-4">
        <v-icon size="20" color="success">fa-table</v-icon> <span class="ml-3">{{use_language.sales_by_sku}}</span>
        <v-spacer/>
        <v-btn size="1" color="success" dark class="mr-1"><v-icon >mdi-file-excel</v-icon>
          <vue-excel-xlsx
              :data="skuTable_list"
              :columns="excel_skuTable_header"
         :file-name='sales_report_by_sku'
        :sheet-name='sales_report_by_sku'
              >
             {{use_language.export_excel}}
          </vue-excel-xlsx>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text >
        <div style="min-height: 350px; max-height: 650px; overflow-y: auto;">
          <v-data-table
            :headers="skuTable_header"
            :items="skuTable_list"
            :items-per-page="5"
            item-key="id"
            class="elevation-0 packhai-border-table"
            style="min-height: 300px"
            mobile-breakpoint="0"
          >
          <template v-slot:item.no="{ item }">
            {{ skuTable_list.indexOf(item) + 1 }}
          </template>
          <template v-slot:item.totalQuantity="{ item }">
            {{  item.totalQuantity  }}
          </template>
          </v-data-table>
        </div>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
import Loading from '@/website/components/Loading'
import { GChart } from 'vue-google-charts'
import axios from 'axios'
import { reportService_dotnet, shopService_dotnet, generalService_dotnet } from '@/website/global'
import { format_number, format_order_id, format_price,get_languages } from '@/website/global_function'
 


// import * as easings from 'vuetify/es5/services/goto/easing-patterns'
  export default {
    components: {
        Loading,
        GChart
    },
    data: () => ({
      header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
      page_loading: true,
      loading: false,
      use_language: null,
      set_language: null,

      param : null,
      search_menu_date_from: false,
      search_date_from: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      search_menu_date_to: false,
      search_date_to: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),


      sum_order_total: 0,
      averageOrderPerDay: 0,
      sum_price_total: 0,
      averageSalePerDay: 0,

      // กราฟ ยอดขายแอดมิน
      chartData_admin: [],
      chartOptions_admin: {
        chart: {
          // pieHole: 10.4,
          width: '100%',
          height: 350,
          legend: { position: 'start' }
        }
      },

      // กราฟ warehouse
      chartData_warehouse: [],
      chartOptions_warehouse: {
        chart: {
          // pieHole: 10.4,
          width: '100%',
          height: 350,
          legend: { position: 'start' }
        }
      },

      // กราฟ chartData_chanal
      chartData_chanal: [],
      chartOptions_chanal: {
        chart: {
          // pieHole: 10.4,
          width: '100%',
          height: 350,
          legend: { position: 'start' }
        }
      },


      // กราฟวงกลม chanal price
      chartPieData_chanal_price: [],

      chartPieOptions_chanal_price: {
        chart: {
          // title: "ยอดขายตามช่องทางการขาย",
          pieHole: 10.4,
          width: '100%',
          height: 450,
          legend: { position: 'bottom' }
        }
      },

      // กราฟวงกลม admin price
      chartPieData_admin_price: [],

      chartPieOptions_admin_price: {
        chart: {
          // title: "ยอดขายตามแอดมิน",
          pieHole: 10.4,
          width: '100%',
          height: 450,
          legend: { position: 'bottom' }
        }
      },

      // กราฟแท่ง  provice
      // chartData_province: null, // the chartData will be created once Chart is ready (needs google charts lib for this)
      chartData_province:[],
      chartOptions_province: {
        chart: {
          curveType: 'function',
          lineWidth: 4,
          height: 450,
          width: '100%',
          intervals: { 'style': 'line' },
          legend: 'start'
        }
      },

      // table 1
      totalReportTable_header: [
        { text: 'ลำดับ',   align: 'center',   value: 'no', width: '10px', sortable: false },
        { text: 'วันที่',   align: 'center',   value: 'reportDate', width: '100px', sortable: false },
        { text: 'พนักงานขาย',   align: 'start', value: 'staffShopName', width:'150px', sortable: false },
        { text: 'ช่องทางการขาย',   align: 'start', value: 'channelName', width:'350px',  sortable: false },
        { text: 'จำนวนออเดอร์',   align: 'end', value: 'orderCount', width: '100px', sortable: false },
        { text: 'ค่าสินค้า',   align: 'end', value: 'productPrice', width: '100px', sortable: false },
        { text: 'ค่าส่ง',   align: 'end', value: 'deliveryCost', width: '100px', sortable: false },
        { text: 'ส่วนลด',   align: 'end', value: 'orderDiscount', width: '100px', sortable: false },
        { text: 'VAT',   align: 'end', value: 'vatAmount', width: '100px', sortable: false },
        { text: 'ยอดขายรวมภาษี',   align: 'end', value: 'totalSale', width: '100px', sortable: false },
        { text: 'ยอดเฉลี่ยต่อออเดอร์',   align: 'end', value: 'averageSalePerOrder', width: '120px', sortable: false },
        { text: 'ยอดขายก่อนภาษี',   align: 'end', value: 'totalSaleBeforeVat', width: '100px', sortable: false },
      ],
      totalReportTable_list:[],

      // table 2
      bestSalseTable_header: [
        { text: 'ลำดับ',   align: 'center',   value: 'no', width: '10px', sortable: false },
        { text: 'รูป',   align: 'center',   value: 'image', width: '15px', sortable: false },
        { text: 'ชื่อสินค้า',   align: 'start', value: 'name', width: '150px', sortable: false },
        { text: 'จำนวน',   align: 'start', value: 'quantity',  width: '100px', sortable: false },
        { text: 'ยอดขาย',   align: 'start', value: 'Sales',  width: '100px', sortable: false },
        { text: '%ยอดขาย',   align: 'end', value: 'percenSalse',  width: '100px', sortable: false },
      ],
      bestSalseTable_list: [],


      // table 3
      provinceTable_header: [
        { text: 'ลำดับ',   align: 'center',   value: 'no', width: '10px', sortable: false },
        { text: 'จังหวัด',   align: 'left',   value: 'province', width: '100px', sortable: false },
        { text: 'จำนวนออเดอร์',   align: 'end', value: 'orderCount', width: '100px', sortable: false },
        { text: 'ยอดขาย',   align: 'end', value: 'totalSale',  width: '100px', sortable: false },
        { text: 'เปอร์เซ็นต์',   align: 'end', value: 'percent',  width: '100px', sortable: false },
      ],
      provinceTable_list: [],

      // table 4
      skuTable_header: [
        { text: 'ลำดับ',   align: 'center',   value: 'no', width: '10px', sortable: false },
        { text: 'วันที่',   align: 'center',   value: 'reportDate', width: '100px', sortable: false },
        { text: 'พนักงานขาย',   align: 'left', value: 'staffShopName', width: '100px', sortable: false },
        { text: '[SKU] สินค้า',   align: 'left', value: 'productName',  width: '100px', sortable: false },
        { text: 'จำนวน',   align: 'end', value: 'totalQuantity',  width: '100px', sortable: false },
      ],
      skuTable_list: [],

      //
      select_admin: [],
      items_admin: [
        'admin1',
        'admin2',
        'admin3',
        'admin4',
      ],

      select_chanal: [],
      items_chanal: [
        'chanal1',
        'chanal2',
        'chanal3',
        'chanal4',
      ],

      select_warehouse: [],
      items_warehouse: [
        'warehouse1',
        'warehouse2',
        'warehouse3',
        'warehouse4',
      ],

      select_provice: [],
      items_provice: [
        'provice1',
        'provice2',
        'provice3',
        'provice4',
      ],


      excel_totalReportTable_header : [
            { label: "วันที่", field: "reportDate", width: 10},
            { label: "พนักงานขาย", field: "staffShopName", width: 50 },
            { label: "ช่องทางการขาย", field: "channelName", width: 100 },
            { label: "จำนวนออเดอร์", field: "orderCount",   width: 15 },
            { label: "ค่าสินค้า", field: "productPrice", width: 10 },
            { label: "ค่าส่ง", field: "deliveryCost", width: 10 },
            { label: "ส่วนลด", field: "orderDiscount", width: 10 },
            { label: "VAT", field: "vatAmount", width: 10},
            { label: "ยอดขายรวมภาษี", field: "totalSale", width: 15},
            { label: "ยอดเฉลี่ยต่อออเดอร์", field: "averageSalePerOrder", width: 15},
            { label: "ยอดขายก่อนภาษี", field: "totalSaleBeforeVat", width: 15},
        ],

      excel_provinceTable_header : [
            { label: "จังหวัด", field: "province", width: 15},
            { label: "จำนวนออเดอร์", field: "orderCount",  width: 15 },
            { label: "ยอดขาย", field: "totalSale", width: 10 },
            { label: "เปอร์เซ็นต์", field: "percent", width: 10 },
        ],

      excel_skuTable_header : [
        { label: "วันที่", field: "reportDate", width: 15},
        { label: "พนักงานขาย", field: "staffShopName", width: 15 },
        { label: "[SKU] สินค้า", field: "productName", width: 10 },
        { label: "จำนวน", field: "totalQuantity", width: 10 },
      ],

    }),
    computed: {

      computed_search_date_from() {
        return this.formatDates(this.search_date_from)
      },
      computed_search_date_to() {
        return this.formatDates(this.search_date_to)
      },

      options () {
        return {
          duration: this.duration,
          offset: this.offset,
          easing: this.easing,
        }
      },
    },
    watch: {

    },
    async created() {
      this.shop_id = localStorage.getItem('shop_id')
      await this.get_data_for_search()


      //เก็บภาษามาไว้ใน use_language
         this.use_language = await this.get_languages(localStorage.getItem("set_language"));


      // get first day of month
      this.search_date_from = this.search_date_from.substring(0, 8) + "01"
      this.param = {

          "dateFrom": this.search_date_from,
          "dateTo": this.search_date_to,
          "shopID": this.shop_id,
          "staffShopList": null, // ถ้ามีจะส่ง list id
          "channelList": null,  // ถ้ามีจะส่ง list id
          "provinceList": null,  // ถ้ามีจะส่ง list id
          "warehouseList": null  // ถ้ามีจะส่ง list id
      }

      await this.getDataReport()
      this.page_loading = false

    },

    methods: {
      format_number, format_order_id, format_price,get_languages,
      allowedDates: val => val <= new Date().toISOString().substr(0, 10),

      formatDates (date) {
        if (date == null){
          return null
        } else {
          const [year, month, day] = date.split('-')
          return `${day}-${month}-${year}`
        }
      },

      async get_data_for_search () {
        await axios.all([
          axios.post(shopService_dotnet + 'Shop/get-warehouse-list',{'shopID':this.shop_id},{ headers: this.header_token}),
          axios.post(shopService_dotnet + 'Shop/get-shop-staff',{'shopID': this.shop_id},{ headers: this.header_token}),
          axios.post(shopService_dotnet + 'Shop/get-shop-channel',{'shopID': this.shop_id},{ headers: this.header_token}),
          axios.post(generalService_dotnet + 'General/get-province',{},{ headers: this.header_token}),
        ]).then(axios.spread((res_WarehouseList, res_Staff, res_ChannelList, res_Province) => {
          this.items_admin = res_Staff.data
          this.items_chanal = res_ChannelList.data
          this.items_warehouse = res_WarehouseList.data
          this.items_provice = res_Province.data

        }));
      },

      SearchingClearData () {
        this.search_date_from = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        this.search_date_to = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        this.select_admin = []
        this.select_chanal = []
        this.select_warehouse = []
        this.select_warehouse = []
      },

      async searching () {
        this.loading = true

        var items_admin = this.select_admin.length == 0 ? null : this.select_admin
        var items_chanal = this.select_chanal.length == 0 ? null : this.select_chanal
        var items_warehouse =  this.select_warehouse.length == 0 ? null : this.select_warehouse
        var items_provice = this.select_warehouse.length == 0 ? null : this.select_warehouse

        this.param = {
            "dateFrom": this.search_date_from,
            "dateTo": this.search_date_to,
            "shopID": this.shop_id, // this.shop_id
            "staffShopList": items_admin, // ถ้ามีจะส่ง list id
            "channelList": items_chanal,  // ถ้ามีจะส่ง list id
            "provinceList": items_provice,  // ถ้ามีจะส่ง list id
            "warehouseList": items_warehouse  // ถ้ามีจะส่ง list id
        }

        this.getDataReport()
      },

      async getDataReport () {
        this.loading = true
        let response = await axios.post(reportService_dotnet+'Report/get-sale-report',this.param , { headers: this.header_token})

        this.loading = false
        var data_report =  response.data

        if(data_report.averageOrderPerDay != null){
          this.sum_order_total = data_report.totalOrder
        }

        if(data_report.averageSalePerDay != null){
          this.sum_price_total = data_report.totalSale
        }

        if(data_report.averageOrderPerDay != null){
          this.averageOrderPerDay = data_report.averageOrderPerDay
        }

        if(data_report.averageSalePerDay != null){
          this.averageSalePerDay = data_report.averageSalePerDay
        }


        if(data_report.graph_sale != null){
          if(data_report.graph_sale.length > 0){
            this.chartData_admin = data_report.graph_sale
          }else {
            this.chartData_admin = []
          }
        }

        if(data_report.graph_warehouse != null){
          if(data_report.graph_warehouse.length > 0){
            this.chartData_warehouse =  data_report.graph_warehouse
          } else {
            this.chartData_warehouse = []
          }
        }

        if(data_report.graph_channel != null){
          if(data_report.graph_channel.length > 0){
            this.chartData_chanal = data_report.graph_channel
          } else {
            this.chartData_chanal = []
          }
        }


        if(data_report.pie_channel != null){
          if(data_report.pie_channel.length > 0){
            this.chartPieData_chanal_price = data_report.pie_channel
          } else {
            this.chartPieData_chanal_price = []
          }
        }

        if(data_report.pie_admin != null){
          if(data_report.pie_admin.length > 0){
            this.chartPieData_admin_price = data_report.pie_admin
          } else {
            this.chartPieData_admin_price = []
          }
        }

        if(data_report.graph_province != null){
          if(data_report.graph_province.length > 0){
            this.chartData_province = data_report.graph_province
          } else {
            this.chartData_province = []
          }
        }

        if(data_report.totalReportTable != null){
          if(data_report.totalReportTable.length > 0){
            this.totalReportTable_list = data_report.totalReportTable
          } else {
            this.totalReportTable_list = []
          }
        }

        //table 2

        if(data_report.proviceReportTable != null) {
          if(data_report.proviceReportTable.length > 0){
            this.provinceTable_list = data_report.proviceReportTable
          } else {
            this.provinceTable_list = []
          }
        }

        //table 3

        if(data_report.skuReportTable != null) {
          if(data_report.skuReportTable.length > 0){
            this.skuTable_list = data_report.skuReportTable
          } else {
            this.skuTable_list = []
          }
        }

      },

    }
  }
</script>
<style scoped>

  >>>.bex48-logo {
      width: 12.533vw;
      height: 11.2vw;
      display: block;
      /* margin: 8.8vw auto 1.6vw; */
      margin: 1.6vw auto 1.6vw;
  }

  .packhai-border-table{
    /* border:1px solid #e9edf4; */
    border-collapse: collapse;
    border-spacing: 0px;
    border-right: 1px solid #e9edf4;
    border-bottom: 1px solid #e9edf4;
    border-top: 1px solid #e9edf4;
  }
  .packhai-border-table thead{
    color:white;
  }
  .packhai-border-table thead tr th{
    color: black !important;
    font-weight: bold;
    font-size: 14px;
  }
  .packhai-border-table tr {
    /* border-collapse: collapse; */
    /* border: 1px solid #e9edf4; */
  }
  .packhai-border-table th {
    /* border-collapse: collapse; */
      border-left: 1px solid #e9edf4;
  }
  .packhai-border-table tr td{
    border-left: 1px solid #e9edf4;
  }

  .text-white {
    color: #fff;
  }

</style>
